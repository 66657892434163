import { ChainId } from '@gizzard-dex/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const chainId = parseInt(CHAIN_ID, 10)
const { MAINNET } = ChainId

export const VLX_WAG_FARM_PID = chainId === MAINNET ? 1 : 1 // WAG-VLX (2)
export const VLX_USDT_FARM_PID = chainId === MAINNET ? 1 : 1 // BUSD-VLX (3)

const farms: SerializedFarmConfig[] =
  chainId === MAINNET
    ? [
        // {
        //   pid: 0,
        //   v1pid: 0,
        //   lpSymbol: 'GIZ',
        //   lpAddresses: {
        //     98865: '0x786214Ee3EB0fE1d96AbBbaaADb06E869036f862',
        //     161221135: '',
        //   },
        //   token: serializedTokens.syrup,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 1,
        //   v1pid: 1,
        //   lpSymbol: 'GIZ/ETH LP',
        //   lpAddresses: {
        //     98865: '0x49d33D9f1D9FF472eae3F14EF914AC9Ed237Caa8',
        //     161221135: '',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
      ]
    : [
        /**
         * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
         */
        {
          pid: 0,
          v1pid: 0,
          lpSymbol: 'GIZ',
          lpAddresses: {
            98865: '',
            161221135: '0xdb18be5580d6f64e54e0dc0cb37e819c7436c1e7',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 1,
          v1pid: 1,
          lpSymbol: 'GIZ/ETH LP',
          lpAddresses: {
            98865: '',
            161221135: '0xd6D72b1C46A2f4b965c2c533B7AF08b59f86b7F6',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
      ]

export default farms
